li {
  list-style-type: none;
}

a {
  color: inherit;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

#Home {
  flex-direction: row;
  height: 100vh;
}
#Home .Homeleft {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Home .Homeleft #greetings {
  width: 90%;
  height: 70%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.5rem;
}
#Home .Homeleft #greetings div {
  display: flex;
  justify-content: center;
  align-items: center;
}
#Home .Homeleft #greetings .hi {
  position: absolute;
  font-size: 5rem;
  top: 0;
  left: 50px;
}
#Home .Homeleft #greetings .hi .im {
  font-size: 3rem;
  position: absolute;
  top: 50%;
  left: 99%;
}
#Home .Homeleft #greetings .hi .im .arash {
  font-size: 5.5rem;
  position: absolute;
  top: 70%;
  left: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-rendering: optimizeLegibility;
  text-shadow: 0px 0px 8px white;
}
#Home .Homeleft #greetings .hi .im .arash .kh {
  position: absolute;
  left: 80%;
  top: 60%;
  text-shadow: 0px 0px 8px white;
}
@media only screen and (max-width: 680px) {
  #Home .Homeleft #greetings .hi {
    top: 0;
    left: 0;
  }
}
@media only screen and (max-width: 680px) and (max-width: 558px) {
  #Home .Homeleft #greetings .hi {
    top: 20%;
  }
}
@media only screen and (max-width: 680px) {
  #Home .Homeleft #greetings .hi .im {
    top: 80%;
    left: 99%;
  }
  #Home .Homeleft #greetings .hi .im .arash {
    top: 120%;
    left: -50%;
  }
  #Home .Homeleft #greetings .hi .im .arash .kh {
    left: 10%;
    top: 100%;
  }
}
#Home .Homeright {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
#Home .Homeright .CircleContainer {
  width: 100vh;
  height: 100vh;
  border: solid 1px black;
  box-shadow: inset 0px 0px 15px black;
  border-radius: 50%;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translateY(-50%) translateX(-15%);
}
@media only screen and (max-width: 680px) {
  #Home .Homeright .CircleContainer {
    width: 70vh;
    height: 70vh;
    left: 0;
  }
}
@media only screen and (max-width: 558px) {
  #Home .Homeright .CircleContainer {
    width: 40vh;
    height: 40vh;
    left: 0;
    top: 30%;
  }
}
#Home .Homeright .CircleContainer figure {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#Home .Homeright .CircleContainer figure img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#Home .Homeright .CircleContainer .littleMoon {
  width: 80px;
  height: 80px;
  position: absolute;
  background: white;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.267);
  top: 50%;
  right: 42rem;
  border-radius: 50%;
  transform-origin: calc(42rem + 100%);
  transform: translateY(-50%) rotate(0deg);
  -webkit-animation: Orbit 10s linear infinite;
          animation: Orbit 10s linear infinite;
}
@-webkit-keyframes Orbit {
  0% {
    transform: translateY(-50%) rotate(-90deg);
  }
  99% {
    transform: translateY(-50%) rotate(60deg);
  }
  100% {
    transform: translateY(-50%) rotate(270deg);
  }
}
@keyframes Orbit {
  0% {
    transform: translateY(-50%) rotate(-90deg);
  }
  99% {
    transform: translateY(-50%) rotate(60deg);
  }
  100% {
    transform: translateY(-50%) rotate(270deg);
  }
}/*# sourceMappingURL=home.css.map */