li {
    list-style-type: none;
}

a {
    color: inherit;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    font-size: 16px; //defualt

    @media only screen and (max-width:1220px) {
        font-size: 14px;
    }

    @media only screen and (max-width:1080px) {
        font-size: 12px;
    }

    @media only screen and (max-width:940px) {
        font-size: 10px;
    }

    @media only screen and (max-width:680px) {
        font-size: 8px;
    }
}

@mixin fcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

//-------------------------------------//


#Home {
    flex-direction: row;
    height: 100vh;
    .Homeleft {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: solid 1px orange;

        #greetings {
            width: 90%;
            height: 70%;
            // border: solid 1px black;
            // background: rgb(196, 196, 196);
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            div {
                // border: solid 1px;
                @include fcenter();
            }
            
            .hi {
                position: absolute;
                font-size: 5rem;
                top: 0;
                left: 50px;

                .im {
                    font-size: 3rem;
                    position: absolute;
                    top: 50%;
                    left: 99%;

                    .arash {
                        font-size: 5.5rem;
                        position: absolute;
                        top: 70%;
                        left: 50%;
                        width: fit-content;
                        text-rendering: optimizeLegibility;
                        text-shadow: 0px 0px 8px white;

                        .kh {
                            position: absolute;
                            left: 80%;
                            top: 60%;
                            text-shadow: 0px 0px 8px white;
                        }
                    }
                }
            }
            @media only screen and (max-width:680px) {
                .hi {
                    top: 0;
                    left: 0;
                    @media only screen and (max-width:558px) {
                        top: 20%;
                    }
                    .im {
                        top: 80%;
                        left: 99%;
                        .arash {
                            top: 120%;
                            left: -50%;
                            .kh {
                                left: 10%;
                                top: 100%;
                            }
                        }
                    }
                }
            }
            font-size: 1.5rem;

        }
    }

    .Homeright {
        width: 40%;
        // border: solid 1px green;
        display: flex;
        justify-content: flex-end;
        // overflow: hidden;
        position: relative;

        .CircleContainer {
            @media only screen and (max-width:680px) {
                width: 70vh;
                height: 70vh;
                left: 0;
            }
            @media only screen and (max-width:558px) {
                width: 40vh;
                height: 40vh;
                left: 0;
                top: 30%;
            }
            width: 100vh;
            height: 100vh;
            border: solid 1px black;
            // background: orange;
            box-shadow: inset 0px 0px 15px black;
            border-radius: 50%;
            position: absolute;
            left: 40%;
            top: 50%;
            transform: translateY(-50%) translateX(-15%);

            figure {
                
                width: 100%;
                height: 100%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .littleMoon {
                width: 80px;
                height: 80px;
                position: absolute;
                background: white;
                box-shadow:0px 0px 15px rgba(255, 255, 255, 0.267);
                top: 50%;
                right: 42rem;
                border-radius: 50%;
                transform-origin: calc(42rem + 100%);
                transform: translateY(-50%) rotate(0deg);
                animation: Orbit 10s linear infinite;
            }

            @keyframes Orbit {
                0%{
                    transform: translateY(-50%) rotate(-90deg);
                }
                99% {
                    transform: translateY(-50%) rotate(60deg);
                }
                100%{
                    transform: translateY(-50%) rotate(270deg);
                }
            }
        }
    }
}