li {
    list-style-type: none;
}

a {
    color: inherit;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    font-size: 16px; //defualt

    @media only screen and (max-width:1220px) {
        font-size: 14px;
    }

    @media only screen and (max-width:1080px) {
        font-size: 12px;
    }

    @media only screen and (max-width:940px) {
        font-size: 10px;
    }

    @media only screen and (max-width:680px) {
        font-size: 8px;
    }
}

@mixin fcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

//-------------------------------------//

#Me {
    flex-direction: row;
    position: relative;
    .leftCircles {
        width: 20%;
        // border: solid 1px black;
        overflow: hidden;
        position: relative;
        // border-style: dashed;
        // color: rgb(212, 212, 212);
        border-width: 5px;
        .CircleContainer {
            width: 35rem;
            height: 35rem;
            background: white;
            box-shadow: 0px 0px 10px white;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translateY(-50%);
            border-radius: 50%;

            .circles {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background: #000;
                position: absolute;
                top: 30%;
                left: 70%;
                box-shadow: inset 0px 0px 10px white;
            }
        }
    }

    .rightParent {
        width: 80%;
        height: 100%;
        @include fcenter();
        flex-direction: column;

        p {
            width: 80%;
            height: fit-content;
            // display: flex;
            // flex-direction: column;
            border: solid 2px rgb(255, 255, 255);
            border-radius: 10px;
            position: relative;
            padding: 2%;
            // display: flex;
            
            
            

            .brac {
                margin: 20px;
                font-size: 120px;
                position: absolute;
                text-shadow: 0px 0px 5px white;
                // top: 0;
                
            }
            #brac1{
                right: 100%;
                bottom: 100%;
                transform: translateY(50%);
            }
            #brac2{
                top: 100%;
                left: 100%;
                // background: white;
                transform: translateY(-80%);

            }

        }
    }
}