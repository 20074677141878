li{
    list-style-type: none;
}

a{
    color: inherit;
}

*,*::after,*::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    background:rgb(22, 22, 22);
    color:rgb(243, 243, 243);
    font-size: 16px; //defualt

    @media only screen and (max-width:1220px) {
        font-size: 14px;
    }

    @media only screen and (max-width:1080px) {
        font-size: 12px;
    }

    @media only screen and (max-width:940px) {
        font-size: 10px;
    }

    @media only screen and (max-width:680px) {
        font-size: 8px;
    }
}

@mixin fcenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

//-------------------------------------//

.workStory{
    width: 95%;
    height: fit-content;
    // background: orange;
    padding: 3% 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 15px 0px;
    border: solid 2px white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px black,
    inset 0px 0px 50px black;
    color: white;




    .box{
        width: 16px;
        height: 16px;
        background: white;
        border-radius: 2px;
        position: relative;
        box-shadow: 0px 0px 5px white;
        .line{
            width: 15vw;
            height: 2px;
            background: white;
            position: absolute;
            top: 50%;
            left: 150%;
            transform: translateY(-50%);
            box-shadow: 0px 0px 3px white;
            a{
                position: absolute;
                left: 110%;
                // top: 50%;
                width: 50vh;
                transform: translateY(-40%);
                width: 40vw;
                // background: #000;
                transition: 0.4s;
                &:hover{
                    color: rgb(243, 243, 243);
                    text-shadow: 0px 0px 5px white;
                }
                
            }
        }
        
    }



    .info{
        // border: solid 1px red;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        p{
            // border: solid 1px green;
            width: 80%;
            margin-right: 10px;
            height: fit-content;
            // width: 50%;
            position: relative;
            display: flex;
            font-size: 16px;
            margin-bottom: 50px;
        }
        figure{
            width: 50%;
            // background: red;
            img{
                width: 100%;
                box-shadow: 0px 0px 10px black;
                border-radius: 5px;
            }
        }
        div.links{
            width: 50%;
            // background: #000;
            padding: 1%;
            display: flex;
            justify-content: end;
            align-items: flex-end;
            a{
                font-size: 2rem;
                margin: 10px;
                // color: white;
                transition: 0.4s;
                &:hover{
                    color: rgb(243, 243, 243);
                    text-shadow: 0px 0px 5px white;
                }
            }
        }
    }
}