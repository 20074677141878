li {
  list-style-type: none;
}

a {
  color: inherit;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  background: rgb(22, 22, 22);
  color: rgb(243, 243, 243);
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

#Works {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Works #WorksParent {
  width: 100%;
  height: 90%;
  padding: 1%;
  border-radius: 5px;
}
#Works #WorksParent .workChart {
  width: 100%;
  height: 100%;
  background: rgb(22, 22, 22);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0px 0px 10px black;
}/*# sourceMappingURL=works.css.map */