li {
  list-style-type: none;
}

a {
  color: inherit;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  background: rgb(22, 22, 22);
  color: rgb(243, 243, 243);
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

.workStory {
  width: 95%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3% 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 15px 0px;
  border: solid 2px white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px black, inset 0px 0px 50px black;
  color: white;
}
.workStory .box {
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 0px 5px white;
}
.workStory .box .line {
  width: 15vw;
  height: 2px;
  background: white;
  position: absolute;
  top: 50%;
  left: 150%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 3px white;
}
.workStory .box .line a {
  position: absolute;
  left: 110%;
  width: 50vh;
  transform: translateY(-40%);
  width: 40vw;
  transition: 0.4s;
}
.workStory .box .line a:hover {
  color: rgb(243, 243, 243);
  text-shadow: 0px 0px 5px white;
}
.workStory .info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.workStory .info p {
  width: 80%;
  margin-right: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  font-size: 16px;
  margin-bottom: 50px;
}
.workStory .info figure {
  width: 50%;
}
.workStory .info figure img {
  width: 100%;
  box-shadow: 0px 0px 10px black;
  border-radius: 5px;
}
.workStory .info div.links {
  width: 50%;
  padding: 1%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}
.workStory .info div.links a {
  font-size: 2rem;
  margin: 10px;
  transition: 0.4s;
}
.workStory .info div.links a:hover {
  color: rgb(243, 243, 243);
  text-shadow: 0px 0px 5px white;
}/*# sourceMappingURL=workComp.css.map */