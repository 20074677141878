li {
  list-style-type: none;
}

a {
  color: inherit;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

#Contact {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  height: 100vh;
}
#Contact .title {
  position: absolute;
  top: 11vh;
  left: 50px;
  padding: 10px;
  border: solid 3px;
  border-radius: 3px;
  text-shadow: 0px 0px 5px white;
  box-shadow: 0px 0px 5px white, inset 0px 0px 5px white;
}
#Contact .ContactParent {
  width: 80%;
  height: 60vh;
  margin-top: 60px;
  background: white;
  border-radius: 100000px;
  color: rgb(24, 24, 24);
  overflow: hidden;
  cursor: default;
}/*# sourceMappingURL=contact.css.map */