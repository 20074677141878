li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

header {
  width: 100%;
  height: 60px;
  display: flex;
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 100;
}
header .icon {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
header ul {
  display: flex;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  padding-right: 20%;
}
header ul li {
  width: 25%;
  height: 100%;
  margin: 0;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
header ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  border: solid 1px rgba(0, 0, 0, 0);
}
header ul li a:hover {
  background: rgb(255, 255, 255);
  transform: scale(1.05);
  color: black;
  box-shadow: 0px 0px 5px white;
}/*# sourceMappingURL=headerStyle.css.map */