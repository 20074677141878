li {
  list-style-type: none;
}

a {
  color: inherit;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

#StartParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgb(22, 22, 22);
  flex-direction: column;
  color: rgb(243, 243, 243);
  -webkit-animation: fadeOut 0.6s forwards 2.1s;
          animation: fadeOut 0.6s forwards 2.1s;
  z-index: 101;
}
#StartParent .logo {
  font-size: 150px;
  -webkit-animation: ComeIn 0.4s forwards 0.8s;
          animation: ComeIn 0.4s forwards 0.8s;
  transform: translateY(80px);
  opacity: 0;
  visibility: hidden;
  text-shadow: 0px 0px 10px white;
}
#StartParent .name {
  font-size: 32px;
  -webkit-animation: ComeIn 0.4s forwards 1s;
          animation: ComeIn 0.4s forwards 1s;
  transform: translateY(80px);
  opacity: 0;
  visibility: hidden;
}

@-webkit-keyframes ComeIn {
  to {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes ComeIn {
  to {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}
@-webkit-keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
  }
}/*# sourceMappingURL=start.css.map */