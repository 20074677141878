li{
    list-style-type: none;
}

a{
    color: inherit;
}

*,*::after,*::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    font-size: 16px; //defualt

    @media only screen and (max-width:1220px) {
        font-size: 14px;
    }

    @media only screen and (max-width:1080px) {
        font-size: 12px;
    }

    @media only screen and (max-width:940px) {
        font-size: 10px;
    }

    @media only screen and (max-width:680px) {
        font-size: 8px;
    }
}

@mixin fcenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

//-------------------------------------//

#StartParent{
    width: 100%;
    height: 100vh;
    @include fcenter();
    position: fixed;
    top: 0%;left: 0%;
    background: rgb(22, 22, 22);
    flex-direction: column;
    color: rgb(243, 243, 243);
    animation: fadeOut 0.6s forwards 2.1s;
    z-index: 101;

    .logo{
        font-size: 150px;
        animation: ComeIn 0.4s forwards 0.8s;
        transform: translateY(80px);
        opacity: 0;
        visibility: hidden;
        text-shadow: 0px 0px 10px white;
    }
    .name{
        font-size: 32px;
        animation: ComeIn 0.4s forwards 1s;
        transform: translateY(80px);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes ComeIn {
    to{
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeOut{
    to{
        opacity: 0;
        visibility: hidden;
    }
}