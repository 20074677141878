li {
  list-style-type: none;
}

a {
  color: inherit;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  background: rgb(22, 22, 22);
  color: rgb(243, 243, 243);
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #252525;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(240, 240, 240);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(206, 206, 206);
}

#root {
  background: rgb(22, 22, 22);
  color: rgb(243, 243, 243);
}

.Section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dot {
  width: 3px;
  height: 50%;
  background: white;
  margin: 0px 120px;
  border-radius: 10px;
  transform: rotate(30deg);
}/*# sourceMappingURL=App.css.map */