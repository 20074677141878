li{
    list-style-type: none;
}

a{
    color: inherit;
    text-decoration: none;
}

*,*::after,*::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    font-size: 16px; //defualt

    @media only screen and (max-width:1220px) {
        font-size: 14px;
    }

    @media only screen and (max-width:1080px) {
        font-size: 12px;
    }

    @media only screen and (max-width:940px) {
        font-size: 10px;
    }

    @media only screen and (max-width:680px) {
        font-size: 8px;
    }
}

@mixin fcenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

//-------------------------------------//

header{
    width: 100%;
    height: 60px;
    display: flex;
    // border: solid 1px black;
    border-bottom: solid 0.5px rgba(255, 255, 255, 0.37);
    @include fcenter();
    position: fixed;
    top: 0;left: 0;
    backdrop-filter: blur(2px);
    z-index: 100;
    .icon{
        width: 10%;
        height: 100%;
        // background: #000;
        @include fcenter();
        font-size: 40px;
        // border: solid 1px red;
    }
    ul{
        display: flex;
        width: 90%;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
        margin: 0;
        padding: 0;
        padding-right: 20%;
        li{
            width: 25%;
            height: 100%;
            // background: rgb(216, 216, 216);
            margin: 0;
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            a{
                width: 100%;
                height: 100%;
                // background: gray;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.4s;
                border: solid 1px rgba(0, 0, 0, 0);
                &:hover{
                    background: rgb(255, 255, 255);
                    transform: scale(1.05);
                    color: black;
                    box-shadow: 0px 0px 5px white;
                }
            }
        }
    }
}