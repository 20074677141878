li {
  list-style-type: none;
}

a {
  color: inherit;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 16px;
}
@media only screen and (max-width: 1220px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  :root {
    font-size: 12px;
  }
}
@media only screen and (max-width: 940px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 680px) {
  :root {
    font-size: 8px;
  }
}

#Me {
  flex-direction: row;
  position: relative;
}
#Me .leftCircles {
  width: 20%;
  overflow: hidden;
  position: relative;
  border-width: 5px;
}
#Me .leftCircles .CircleContainer {
  width: 35rem;
  height: 35rem;
  background: white;
  box-shadow: 0px 0px 10px white;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
#Me .leftCircles .CircleContainer .circles {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 30%;
  left: 70%;
  box-shadow: inset 0px 0px 10px white;
}
#Me .rightParent {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#Me .rightParent p {
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: solid 2px rgb(255, 255, 255);
  border-radius: 10px;
  position: relative;
  padding: 2%;
}
#Me .rightParent p .brac {
  margin: 20px;
  font-size: 120px;
  position: absolute;
  text-shadow: 0px 0px 5px white;
}
#Me .rightParent p #brac1 {
  right: 100%;
  bottom: 100%;
  transform: translateY(50%);
}
#Me .rightParent p #brac2 {
  top: 100%;
  left: 100%;
  transform: translateY(-80%);
}/*# sourceMappingURL=about.css.map */